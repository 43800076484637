import { useEffect, useRef, useState } from "react";
import "./tiffinclub.css";
import RunnerUp from "../../components/runnerup/runnerup";
import deliveroologo from "./../../assets/img/deliveroologo.png";
import TiffinClubImg from "../../assets/img/tiffinclub/tiffinclub.jpeg";
import TiffinClub2Img from "../../assets/img/tiffinclub/tiffinclub-2.jpeg";
import TiffinCarrierImg from "../../assets/img/tiffinclub/tiffincarrier-3.jpg";
import TiffinClubLogo from "../../assets/img/tiffinclub/logopink.png";
import "../contact/contact.css";
import emailjs from "emailjs-com";
import { MdClose } from "react-icons/md";

function TiffinClub() {
  const form = useRef();
  const [showForm, setShowForm] = useState(false);
  const [name, setname] = useState("");

  const nameonchangehandler = (e) => {
    setname(e.target.value);
  };

  const [email, setemail] = useState("");
  const emailonchangehandler = (e) => {
    setemail(e.target.value);
  };

  const [phone, setphone] = useState("");
  const phoneonchangehandler = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "");
    setphone(numericValue);
  };

  const [address, setaddress] = useState("");
  const addressonchangehandler = (e) => {
    setaddress(e.target.value);
  };
  const [postcode, setpostcode] = useState("");
  const postcodeonchangehandler = (e) => {
    setpostcode(e.target.value);
  };
  const [foodpreference, setfoodpreference] = useState("");
  const foodpreferenceonchangehandler = (e) => {
    setfoodpreference(e.target.value);
  };
  const [allergy, setallergy] = useState("");
  const allergyonchangehandler = (e) => {
    setallergy(e.target.value);
  };

  const [spicelevel, setspicelevel] = useState("");
  const spicelevelonchangehandler = (e) => {
    setspicelevel(e.target.value);
  };

  const [message, setmessage] = useState("");
  const messageonchangehandler = (e) => {
    setmessage(e.target.value);
  };

  const sendEmail = (e) => {
    console.log("sending email");
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mtrgzn4",
        "template_lu0lshd",
        form.current,
        "Adx2z66adQ-5yfhHj"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message Sent.");
          setname("");
          setemail("");
          setphone("");
          setaddress("");
          setpostcode("");
          setallergy("");
          setfoodpreference("");
          setspicelevel("");
          setmessage("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  // Toggle form visibility
  const toggleFormVisibility = () => {
    setShowForm((prev) => !prev);
  };

  useEffect(() => {
    document.title = "TiffinClub";
    window.scrollTo(0, 0);
  }, []);

  const altImg = "tiffinClub";

  return (
    <>
      <div className="el tiffinclub-container">
        <div className="intro-section">
          <div className="logo-and-heading">
            <img src={TiffinClubLogo} alt="TiffinClub Logo" className="logo" />
            <h2 className="heading">Welcome to the Tiffin Club!</h2>
          </div>
          <p className="intro-text">
            Our subscription service delivers freshly prepared, authentic Indian
            meals to your home every Wednesday.
          </p>
        </div>
        <div className="content-block">
          <img
            src={TiffinClubImg}
            alt="Tiffin Club Meal"
            className="content-image"
          />
          <div className="content-text">
            <h2>🍽️ Home-Style Cooking with Seasonal Ingredients</h2>
            <p>
              Savor the taste of home-cooked meals made with fresh, seasonal
              ingredients and authentic regional flavours. Our dishes are
              crafted using cherished recipes passed down through generations
              that rarely find their way onto restaurant menus.
            </p>
          </div>
        </div>
        <div className="content-block">
          <div className="content-text">
            <h2>🗓️ Surprise Menus with Seasonal Ingredients</h2>
            <p>
              Every Monday, we’ll reveal a unique menu featuring seasonal
              ingredients. Enjoy vibrant flavours and hidden Indian recipes that
              are far from repetitive.
            </p>
            <h2 className="content-text">🍱 Traditional Tiffin Carrier</h2>
            <p>
              Meals are delivered in a 4-tier stainless steel tiffin carrier.
              Generous portions serve 2 people, ready to be reheated and
              enjoyed. Every meal is freshly prepared, never reheated by us.
            </p>
            <h2 className="content-text">🥘 Dietary Options</h2>
            <p>
              Choose from Vegan, Vegetarian, or Meat dishes. Our plant-based
              meals are so flavourful, you may consider making meat an option,
              not a necessity!
            </p>
          </div>
          <img
            src={TiffinClub2Img}
            alt="Tiffin Club Meal"
            className="content-image"
          />
        </div>

        <div className="content-block">
          <img
            src={TiffinCarrierImg}
            alt="Tiffin Club Meal"
            className="content-image"
          />
          <div className="card-container">
            <div className="content-text">
              <h2>💷 Subscription Options & Pricing</h2>
              <ul>
                <li>
                  <strong>One Tiffin a Week:</strong> Delivered weekly or opt
                  for a fortnightly option.
                </li>
                <li>
                  <strong>Pricing:</strong> £35 for Vegetarian and £45 for Meat
                  options.
                </li>
                <li>
                  <strong>Deposit:</strong> £25 for the tiffin carrier.
                </li>
                <li>
                  <strong>Delivery Fee:</strong> £5 for locations over 3 miles
                  from SE24, CR8.
                </li>
                <li>
                  <strong>Delivery Areas:</strong> Zones 1-6, including
                  Orpington and Bromley in Kent.
                </li>
                <li>
                  <strong>Payments & Cancellation:</strong> This is a
                  subscription service with payments made in advance. If you
                  need to cancel, just provide a week’s notice, and we’ll
                  collect the tiffin carrier or refund the deposit upon return.
                </li>
              </ul>
            </div>
            <div className="links2">
              <button
                style={{ backgroundColor: "#000", color: "#fec722" }}
                onClick={toggleFormVisibility}
              >
                Subscribe Now
              </button>
            </div>
          </div>
        </div>

        {/* Conditionally render the form */}
        {showForm && (
          <div className="contact">
            <div className="form-overlay">
              <div className="form">
                <div className="form_container">
                  <span className="close-button" onClick={toggleFormVisibility}>
                    <MdClose size={27} />
                  </span>
                  <h2>Contact Us</h2>
                  <p>
                    Send a DM to discuss dietary needs or allergies, and we’ll
                    share payment details.
                  </p>
                  <form ref={form} autoComplete="off" onSubmit={sendEmail}>
                    <div className="form_group">
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={nameonchangehandler}
                        placeholder="Name"
                        id="name"
                        required
                        autoFocus
                      />
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={emailonchangehandler}
                        placeholder="Email"
                        id="email"
                        required
                      />
                    </div>
                    <div className="form_group">
                      <input
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={phoneonchangehandler}
                        placeholder="Phone No."
                        id="phone"
                        required
                        inputMode="numeric" // Ensures numeric keyboard on mobile
                        pattern="[0-9]*" // Ensures numeric input pattern
                      />
                      <input
                        type="text"
                        name="address"
                        value={address}
                        onChange={addressonchangehandler}
                        placeholder="Address"
                        id="address"
                        required
                      />
                    </div>
                    <div className="form_group">
                      <input
                        type="text"
                        name="postcode"
                        value={postcode}
                        onChange={postcodeonchangehandler}
                        placeholder="Postcode"
                        id="postcode"
                        required
                      />
                      <input
                        type="text"
                        name="allergy"
                        value={allergy}
                        onChange={allergyonchangehandler}
                        placeholder="Allergies (if any)"
                        id="Allergy"
                      />
                    </div>
                    <div className="form_group">
                      <select
                        name="foodpreference"
                        value={foodpreference}
                        onChange={foodpreferenceonchangehandler}
                        required
                      >
                        <option value="" disabled>
                          Dietary Preference
                        </option>
                        <option value="Vegan">Vegan</option>
                        <option value="Vegetarian">Vegetarian</option>
                        <option value="Non-Vegetarian">Non-Vegetarian</option>
                      </select>
                    </div>
                    <div className="form_group">
                      <select
                        name="spicelevel"
                        value={spicelevel}
                        onChange={spicelevelonchangehandler}
                        required
                      >
                        <option value="" disabled>
                          Spice Level
                        </option>
                        <option value="Mild">Mild</option>
                        <option value="Medium">Medium</option>
                        <option value="Hot">Hot</option>
                      </select>
                    </div>
                    <div className="form_group">
                      <input
                        type="text"
                        name="message"
                        value={message}
                        onChange={messageonchangehandler}
                        placeholder="Your Message"
                        id="message"
                      />
                    </div>
                    <button type="submit" name="button">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TiffinClub;
