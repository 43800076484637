import { useEffect } from "react";
import "./eachlocation.css";
import RunnerUp from "./../../components/runnerup/runnerup";
import deliveroologo from "./../../assets/img/deliveroologo.png";

function Battersea() {
  useEffect(() => {
    document.title = "BatterSea";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="el">
      <div className="first" id="battersea">
        <h1 style={{color:'white'}}>BatterSea</h1>
      </div>

      <div className="second">
        <h2>Find us at BatterSea</h2>

        <div class="links">
          <a href="https://deliveroo.co.uk/menu/London/battersea/curry-on-naanstop-editions-bqr?geohash=gcpuu4px5x9j">
            Order with <img src={deliveroologo} alt="" />
          </a>
        </div>
      </div>

      <div className="third">
        <div className="third_1">
          <iframe
            title="Google Maps - Curry On Naanstop Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1441.9013683262276!2d-0.15664969906823795!3d51.47084218356625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605b40554e011%3A0x3673f54e6ab0e4d5!2s15a%20Culvert%20Pl%2C%20London%20SW11%205AY%2C%20UK!5e0!3m2!1sen!2sin!4v1731486423238!5m2!1sen!2sin"
            width={600}
            height={550}
            style={{ border: "0px" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="third_2">
          <div className="contact_us">
            <h4>Address</h4>
            <span>
              15A, Culvert place
              <br /> Battersea, SW11 5BA
            </span>
          </div>
          <div className="contact_us">
            <h4>Opening Hours</h4>
            <span>
              Monday to Friday - 12-10PM <br /> Saturday-10am to 10 pm <br />{" "}
              Sunday 10am to 9pm
            </span>
          </div>
          <div className="contact_us">
            <h4>Contact Us</h4>
            <span>info@curryonnaanstop.com</span>
            <br />
            <span>020 3929 7560</span>
          </div>
        </div>
      </div>
      <RunnerUp />
    </div>
  );
}

export default Battersea;
