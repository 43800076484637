import "./footer.css";
import footer_logo from "./../../assets/img/index/footer_logo.png";
import ins_logo from "./../../assets/img/index/instagram.png";
import fb_logo from "./../../assets/img/index/facebook.png";
import you_logo from "./../../assets/img/index/youtubei.png";
import twi_logo from "./../../assets/img/index/twitterx.png";
import lin_logo from "./../../assets/img/index/linkedin.png";
import { useEffect, useState } from "react";
import { MdArrowUpward } from "react-icons/md";
import down_arrow from "./../../assets/img/index/down_arrow.png";

function Footer() {
  return (
    <footer>
      <div className="footer_1">
        <div className="footer_1_1">
          <div className="footer_logo">
            <img src={footer_logo} alt="" />
          </div>
          <div className="footer_social">
            <a
              href="https://www.instagram.com/curryonnaanstop/profilecard/?igsh=MXU4eTR3aG9qcDZwdg=="
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={ins_logo} alt="" />{" "}
            </a>
            <a
              href="https://www.facebook.com/share/14JzfGh4M6/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={fb_logo} alt="" />{" "}
            </a>
            <a
              href="https://www.youtube.com/@curryonnaanstop1604"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={you_logo} alt="" />{" "}
            </a>
            <a
              href="https://x.com/curryonnaanstop"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={twi_logo} alt="" />{" "}
            </a>
            <a
              href="https://www.linkedin.com/company/curry-on-naanstop/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={lin_logo} alt="" />{" "}
            </a>
          </div>
        </div>
        <div className="footer_1_2">
          <div className="footer_1_2_links">
            <a href="#">Search Dishes</a>
            <a href="#">Order Online</a>
            <a href="#">Tiffin Club</a>
          </div>
          <div className="footer_1_2_links">
            <a href="#">FAQs</a>
            <a href="#">Corporate Hire & Functions</a>
            <a href="#">Wedding & Private Hire</a>
          </div>
        </div>
      </div>
      <div className="footer_2">
        <span>© 2024 CURRY ON NAANSTOP</span>
      </div>
    </footer>
  );
}

export default Footer;
