import { useEffect } from "react";
import "./eachlocation.css";
import RunnerUp from "./../../components/runnerup/runnerup";
import deliveroologo from "./../../assets/img/deliveroologo.png";

function Canningtown() {
  useEffect(() => {
    document.title = "Canning Town";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="el">
      <div className="first" id="canningtown">
        <h1 style={{color:'white'}}>Canning Town</h1>
      </div>

      <div className="second">
        <h2>Find us at Canning Town</h2>

        <div class="links">
          <a href="https://deliveroo.co.uk/menu/London/editions-canning-town/curry-on-naanstop-editions-can?geohash=u10j069xes6e">
            Order with <img src={deliveroologo} alt="" />
          </a>
        </div>
      </div>

      <div className="third">
        <div className="third_1">
          <iframe
            title="Google Maps - Curry On Naanstop Location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2054.4873470942434!2d-0.0009220038009082578!3d51.52328910811271!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a7f87879d6a9%3A0x9ed5e6b653b7fe23!2sCrescent%20Court%20Business%20Centre!5e0!3m2!1sen!2sin!4v1731486890500!5m2!1sen!2sin"
            width={600}
            height={550}
            style={{ border: "0px" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="third_2">
          <div className="contact_us">
            <h4>Address</h4>
            <span>
              Unit 3 & 4, North Crescent <br /> Business Centre, E16 4TG
            </span>
          </div>
          <div className="contact_us">
            <h4>Opening Hours</h4>
            <span>
              Monday to Friday - 12-10PM <br /> Saturday-10am to 10 pm <br />{" "}
              Sunday 10am to 9pm
            </span>
          </div>
          <div className="contact_us">
            <h4>Contact Us</h4>
            <span>info@curryonnaanstop.com</span>
            <br />
            <span>020 3929 7560</span>
          </div>
        </div>
      </div>

      <RunnerUp />
    </div>
  );
}

export default Canningtown;
