import { useEffect } from "react";
import "./eachlocation.css";
import "./cucumber.css";
import RunnerUp from "./../../components/runnerup/runnerup";
import croydonmenu from "./../../assets/pdfs/croydonmenu.pdf";
import croydonallergenmatrix from "./../../assets/pdfs/croydonallergenmatrix.pdf";

function Croydon() {
  useEffect(() => {
    document.title = "Box Park Croydon";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="el">
      <div className="first" id="croydon">
        <h1 style={{ color: "white" }}>Box Park Croydon</h1>
      </div>

      <div className="second">
        <h2>Find us at Box Park Croydon</h2>

        <div className="links" style={{ gap: "25px" }}>
          <a href={croydonmenu} target="_blank" rel="noreferrer">
            Menus
          </a>
          <a href={croydonallergenmatrix}>Allergen Matrix</a>
        </div>
      </div>

      <div className="third">
        <div className="third_1">
          <iframe
            title="Google Maps - Curry On Naanstop Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.5381926734785!2d-0.09625322388094744!3d51.374785920383204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876073334644b3d%3A0x4354a2566158c43b!2sBoxpark%2C%2099%20George%20St%2C%20Croydon%20CR0%201LD%2C%20UK!5e0!3m2!1sen!2sin!4v1731487343405!5m2!1sen!2sin"
            width={600}
            height={550}
            style={{ border: "0px" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="third_2">
          <div className="contact_us">
            <h4>Address</h4>
            <span>
              99 George St, <br /> Croydon CR0 1LD
            </span>
          </div>
          <div className="contact_us">
            <h4>Opening Hours</h4>
            <span>
              Monday to Friday - 12-10PM <br /> Saturday-10am to 10 pm <br />{" "}
              Sunday 10am to 9pm
            </span>
          </div>
          <div className="contact_us">
            <h4>Contact Us</h4>
            <span>info@curryonnaanstop.com</span>
            <br />
            <span>020 3929 7560</span>
          </div>
        </div>
      </div>

      <RunnerUp />
    </div>
  );
}

export default Croydon;
