import "./header.css";
import logo from "./../../assets/img/index/logo.png";

import { BrowserRouter as Router, Link, NavLink } from "react-router-dom";

function Header() {
  return (
    <header>
      <div className="header_bg"></div>
      <div className="header_container">
        <div className="header_logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="header_nav">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "headermenu active" : "headermenu"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/our-menu"
            className={({ isActive }) =>
              isActive ? "headermenu active" : "headermenu"
            }
          >
            Menus
          </NavLink>
          <NavLink
            to="/our-locations"
            className={({ isActive }) =>
              isActive ? "headermenu active" : "headermenu"
            }
          >
            Locations
          </NavLink>
          <NavLink
            to="/food-truck"
            className={({ isActive }) =>
              isActive ? "headermenu active" : "headermenu"
            }
          >
            Food Truck
          </NavLink>
          <NavLink
            to="/tiffinclub"
            className={({ isActive }) =>
              isActive ? "headermenu" : "headermenu"
            }
          >
            Tiffin Club
          </NavLink>
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              isActive ? "headermenu active" : "headermenu"
            }
          >
            Contact Us
          </NavLink>
        </div>
      </div>
    </header>
  );
}
export default Header;
